import '../styles/index.scss';
import $ from 'jquery';
import 'bootstrap/js/dist/carousel';

// console.log('webpack starterkit');

;(function (window) {
    function createMap (mapid, tiles) {
    const minZoom = 0;
    const maxZoom = 5;
    const img = [
      5000, // original width of image
      3776  // original height of image
    ];

    // create the map
    const map = L.map(mapid, {
      minZoom: minZoom,
      maxZoom: maxZoom,
      wheelPxPerZoomLevel: 240,
      zoomDelta: 0.5,
      zoomSnap: 0.5,
      zoomControl: false
    }).setView([60, -70], 3);
    // map.fitBounds([[85,75],[15,60]]);
    // map.flyTo([85,170]);

    // assign map and image dimensions
    // var rc = new L.RasterCoords(map, img);

    L.tileLayer(`./public/tiles/${tiles}/{z}/{x}/{y}.png`, {
      noWrap: true,
      attribution: 'Map designed and produced by Nick Springer, Springer Cartographics LLC. Reprinted with permission from Neal Stephenson, published by HarperCollins Publishers, Inc. Copyright &copy; 2003 Neal Stephenson.'
    }).addTo(map);

    map.addEventListener('click', function(ev) {
      const lat = ev.latlng.lat;
      const lng = ev.latlng.lng;
      console.log("lat:",lat,"lon:",lng);
    });

    map.fitBounds([[85,-175],[15,30]]);
    }
    
    createMap("map1","quicksilver_europe");
    createMap("map2","quicksilver_london");
    createMap("map3","confusion_west");
    createMap("map4","confusion_east");
    createMap("map5","system_london");
    createMap("map6","system_thames");
}(window));

$(()=> {


    $("#show-more").on("click",(e)=>{
        $(e.target).toggleClass("d-none");
        $("#show-less").toggleClass("d-none");
        $("#more-description").toggleClass("d-none");
    });

    $("#show-less").on("click",(e)=>{
        $(e.target).toggleClass("d-none");
        $("#show-more").toggleClass("d-none");
        $("#more-description").toggleClass("d-none");
    });

    
});